import dayjs from 'dayjs';
import 'dayjs/locale/de';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';

dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale('de');

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
