import React, { lazy, Suspense } from 'react';

const Footer = lazy(() => import('./components/footer'));
const Setup = lazy(() => import('./components/setup'));
const Style = lazy(() => import('./components/style'));
const Offline = lazy(() => import('./components/offline'));
const Ixo = lazy(() => import('./components/ixo'));
const HecInfoProvider = lazy(() => import('./components/store'));

export const App = () => {
  return (
    <Suspense fallback={<div className="loader is-global" />}>
      <HecInfoProvider>
        <Style />
        <Ixo />
        <Offline />
        <Setup />
        <Footer />
      </HecInfoProvider>
    </Suspense>
  );
};
